import { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Select, MenuItem} from "@mui/material";

import NewEncounterFormStyle from './NewEncounterForm.module';
import DiagnoseSelect from './DiagnoseSelect';
import ProcedureSelect from './ProcedureSelect';
import {
  MAX_NUM_OF_DIAGNOSES,
  INITIAL_TREATMENT_INPUT_VALUE,
  MAX_NUM_OF_PROCEDURES,
  MAX_NUM_OF_TREATMENTS,
} from '../../config/variables';
import AuthContext from '../../../contexts/auth-context';
import useAxios from '../../../hooks/useAxios';
import { urls, app_paths, http_response_messages, toastify_status } from '../../config/variables';
import { useNavigate } from 'react-router-dom';
import TreatmentForm from './TreatmentForm';
import { RemoveInput, FilterNonNullInputs, DeleteItem, AddMoreInputs } from './formUtils';
import AppContext from "../../../contexts/app-context";
// Circular Progress from Material UI
import CircularProgress from '@mui/material/CircularProgress';
import {useTranslation} from 'react-i18next'
import Toastify from '../../ui/Toastify';
import { validateAnEncounter, validateFavourite } from '../../../Utils/validateHttpResponse';
import { company, companiesObject } from '../../config/variables';

const NewEncounterForm = () => {
  const classes = NewEncounterFormStyle();
  const navigate = useNavigate();
  const {t} = useTranslation('newEncounter')

  const INITIAL_DIAGNOSIS_INPUT = [
    {
      index: 0,
      label: t('PrimaryDiagnosis'),
      name: 'primary',
    },
  ];
   const INITIAL_PROCEDURES_INPUT = [
    {
      index: 0,
      label: t('PrimaryProcedure'),
      name: 'Procedure 0',
    },
  ];
  
   const INITIAL_TREATMENT_INPUT = [
    {
      index: 0,
      label: t('PrimaryTreatment'),
    },
  ];

  const appCtx = useContext(AppContext);
  const userRole = appCtx.userRole;
  const [insurance, setInsurance] = useState(1);
  const [favourite, setFavourite] = useState([]);

  /* Diagnosis states*/
  const [diagnoseInputs, setDiagnoseInputs] = useState(INITIAL_DIAGNOSIS_INPUT);
  const [diagnoses, setDiagnoses] = useState([]);
  const filteredDiagnoseInputs = FilterNonNullInputs(diagnoseInputs);
  const filteredDiagnoseInputsLength = filteredDiagnoseInputs.length;
  const filteredDiagnoses = FilterNonNullInputs(diagnoses);
  const filteredDiagnosesLength = filteredDiagnoses.length;
  /* Procedure states*/
  const [procedureInputs, setProcedureInputs] = useState(INITIAL_PROCEDURES_INPUT);
  const [procedures, setProcedures] = useState([]);
  const filteredProcedureInputs = FilterNonNullInputs(procedureInputs);
  const filteredProcedureInputsLength = filteredProcedureInputs.length;
  const filteredProcedures = FilterNonNullInputs(procedures);
  const filteredProceduresLength = filteredProcedures.length;

  // disable the submit button if there is missing encounter data
  const [disableSubmit, setDisableSubmit] = useState(true);
  // disable the submit button when get clicked
  const [disableSubmitted, setDisableSubmitted] = useState(false);

  /* Treatment states */
  const [treatmentInputsValue, setTreatmentInputsValue] = useState(INITIAL_TREATMENT_INPUT_VALUE);
  const [treatmentInputs, setTreatmentInputs] = useState(INITIAL_TREATMENT_INPUT);
  const filteredTreatmentInputs = FilterNonNullInputs(treatmentInputs);
  const filteredTreatmentInputsLength = filteredTreatmentInputs.length;
  // the treatment inputs that has validation errors in its fields
  // const [treatmentInputsIndexValidationErrors, setTreatmentInputsIndexValidationErrors] = useState([]); ---> related to disabled submit button

  const filteredTreatmentInputsValue = useCallback(() => {
    return treatmentInputsValue.filter(
      (treatmentInputValue) =>
        treatmentInputValue !== null &&
        treatmentInputValue !== undefined &&
        (treatmentInputValue.frequency ||
          treatmentInputValue.product_id ||
          treatmentInputValue.drug_dose ||
          treatmentInputValue.dosage_form ||
          treatmentInputValue.claimed_quantity ||
          treatmentInputValue.duration)
    );
  }, [treatmentInputsValue])();
  const filteredTreatmentInputsValueLength = filteredTreatmentInputsValue.length;

  /* Doctor Notes */
  const [doctorNote, setDoctorNote] = useState("")

  /* New encounter states */
  const [newEncounter, setNewEncounter] = useState();
  const [newEncounterError, setNewEncounterError] = useState(undefined);

  const [clearSearch, setClearSearch] = useState(false);
  const authCtx = useContext(AuthContext);
  const { operation } = useAxios();

  const getFavourite = () => {
    operation(
      {
        method: 'GET',
        url: urls.FAVOURITE,
        headers: { Authorization: `token ${authCtx.token}` },
      },
      handleSetFavourite,
      (error) => {
        console.log(error)
        Toastify({message: 'error favourite response', state: toastify_status.ERROR})
      }
    );
  }

  const handleSetFavourite = (data) => {
    if (data && validateFavourite(data)) {
      setFavourite(data)
    } else {
      Toastify({message: 'invalid favourite response', state: toastify_status.ERROR})
    }
  }

  useEffect(() => {
    getFavourite()
    // eslint-disable-next-line
  }, [])

  const updateFavourite = useCallback((data) => {
    operation(
      {
        method: 'PUT',
        url: urls.FAVOURITE,
        headers: { Authorization: `token ${authCtx.token}` },
        data,
      },
      handleUpdateFavourite,
      (error) => {
        console.log(error)
        Toastify({message: 'error update favourite response', state: toastify_status.ERROR})
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdateFavourite = (data) => {
    if (data && validateFavourite(data)) {
      getFavourite()
    } else {
      Toastify({message: 'invalid updated favourite response', state: toastify_status.ERROR})
    }
  }

  const handleTreatmentInputValue = useCallback((event, index) => {
    const target = event.target;
    setTreatmentInputsValue((prevInputsValue) => {
      if (prevInputsValue.length >= index) {
        const tempTreatments = [...prevInputsValue];
        tempTreatments[index][target.name] = target.value;
        return tempTreatments;
      }
      return prevInputsValue;
    });
  }, []);
  const handleDeleteDrugValue = DeleteItem(setTreatmentInputsValue, 'product_id');
  //the fourth argumen has a number used when add new treatment but no need for this number when revove treatment input
  const handleRemoveTreatmentForm = RemoveInput(setTreatmentInputs, setTreatmentInputsValue, t("Treatment", {number: ''}));

  //add the index of the treatment inputs form that has error validations on its fields ---> related to disabled submit button
  // const handleSetTreatmentInputsIndexValidationErrors = (operation, index) => {
  //   if(operation === 'add') {
  //     setTreatmentInputsIndexValidationErrors((preV) => {
  //       if(preV.includes(index)) {
  //         return preV
  //       } else {
  //         return [...preV, index]
  //       }
  //     })
  //   } else {
  //     setTreatmentInputsIndexValidationErrors(preV => preV.filter(num => num !== index))
  //   }
  // }

  const TreatmentInputsForm = useMemo(() => {
    return treatmentInputs.map((treatmentInput) => {
      const indexOfInput = filteredTreatmentInputs.indexOf(treatmentInput);
      const isDisabled = indexOfInput > filteredTreatmentInputsValueLength;
      if (treatmentInput) {
        return (
          <TreatmentForm
            key={treatmentInput.index}
            treatmentInput={treatmentInput}
            treatmentInputsValue={treatmentInputsValue}
            handleTreatmentInputValue={handleTreatmentInputValue}
            handleRemoveTreatmentForm={handleRemoveTreatmentForm}
            clearSearch={clearSearch}
            handleDeleteDrugValue={handleDeleteDrugValue}
            isDisabled={isDisabled}
            enounterError={newEncounterError}
            diagnoses={diagnoses}
            favourite={favourite}
            updateFavourite={updateFavourite}
            // handleSetTreatmentInputsIndexValidationErrors={handleSetTreatmentInputsIndexValidationErrors} ---> related to disabled submit button
          />
        );
      } else {
        return null;
      }
    });
  }, [
    treatmentInputs,
    treatmentInputsValue,
    handleTreatmentInputValue,
    handleRemoveTreatmentForm,
    clearSearch,
    handleDeleteDrugValue,
    filteredTreatmentInputs,
    filteredTreatmentInputsValueLength,
    newEncounterError,
    diagnoses,
    favourite,
    updateFavourite,
  ]);

  const handleAddMoreTreatmentInputs = () => {
    if (filteredTreatmentInputsLength < MAX_NUM_OF_TREATMENTS) {
      setTreatmentInputsValue((prevTreatmentValue) => [...prevTreatmentValue, { ...INITIAL_TREATMENT_INPUT_VALUE[0] }]);
      setTreatmentInputs((prevTreatmentInputs) => [
        ...prevTreatmentInputs,
        {
          index: prevTreatmentInputs.length,
          label: t("Treatment", {number: filteredTreatmentInputsLength + 1}),
        },
      ]);
    }
  };

  /* This function is used to handle the selection of a diagnosis in the DiagnoseSelect component.
   It takes in an event and an index as arguments , where index is the index of the diagnosis input field that is being updated. */
  const handleSelectDiagnose = useCallback((event, index) => {
    const target = event.target;
    setDiagnoses((prevDiagnosesArr) => {
      if (prevDiagnosesArr.length >= index) {
        const tempDiagnoses = [...prevDiagnosesArr];
        tempDiagnoses[index] = {
          icd: target.value,
          primary: target.name === 'primary',
        };
        return tempDiagnoses;
      } else {
        return prevDiagnosesArr;
      }
    });
  }, []);

  const handleDeleteDiagnose = DeleteItem(setDiagnoses);

  const handleRemoveDiagnoseInput = RemoveInput(setDiagnoseInputs, setDiagnoses, t("Diagnosis"));

  /* This function maps over the diagnoseInputs array to create a DiagnoseSelect component for each input.
 It uses the useMemo hook to memoize the array of components for better performance. */
  const DiagnosesSelectInputs = useMemo(() => {
    return diagnoseInputs.map((diagnoseInput) => {
      const indexOfInput = filteredDiagnoseInputs.indexOf(diagnoseInput);
      const isDisabled = indexOfInput > filteredDiagnosesLength;
      if (diagnoseInput) {
        return (
          <DiagnoseSelect
            key={diagnoseInput.index}
            diagnoseInput={diagnoseInput}
            diagnoses={diagnoses}
            handleSelectDiagnose={handleSelectDiagnose}
            clearSearch={clearSearch}
            handleDeleteDiagnose={handleDeleteDiagnose}
            handleRemoveDiagnoseInput={handleRemoveDiagnoseInput}
            isDisabled={isDisabled}
            favourite={favourite}
            updateFavourite={updateFavourite}
          />
        );
      } else {
        return null;
      }
    });
  }, [
    diagnoseInputs,
    diagnoses,
    handleSelectDiagnose,
    clearSearch,
    handleDeleteDiagnose,
    handleRemoveDiagnoseInput,
    filteredDiagnoseInputs,
    filteredDiagnosesLength,
    favourite,
    updateFavourite,
  ]);

  /* This function is used to handle the addition of a new diagnosis input to the diagnoseInputs array when the "Add More" button is clicked.*/
  const handleAddMoreDiagnoseInput = () => {
    AddMoreInputs(setDiagnoseInputs, diagnoseInputs, filteredDiagnoseInputsLength, t("Diagnosis"), MAX_NUM_OF_DIAGNOSES);
  };

  //Start Handle Procedures

  const handleSelectProcedure = useCallback((event, index) => {
    const value = event.target.value;
    setProcedures((prevProcedureValue) => {
      if (prevProcedureValue.length >= index) {
        const tempProcedures = [...prevProcedureValue];
        tempProcedures[index] = {
          cpt: value,
        };
        return tempProcedures;
      } else {
        return prevProcedureValue;
      }
    });
  }, []);

  const handleDeleteProcedure = DeleteItem(setProcedures);

  const handleRemoveProcedureInput = RemoveInput(setProcedureInputs, setProcedures, t("Procedure"));

  const ProceduresSelectInputs = useMemo(() => {
    return procedureInputs.map((procedureInput) => {
      const indexOfInput = filteredProcedureInputs.indexOf(procedureInput);
      const isDisabled = indexOfInput > filteredProceduresLength;
      if (procedureInput) {
        return (
          <ProcedureSelect
            key={procedureInput.index}
            procedureInput={procedureInput}
            procedures={procedures}
            handleSelectProcedure={handleSelectProcedure}
            clearSearch={clearSearch}
            handleDeleteProcedure={handleDeleteProcedure}
            handleRemoveProcedureInput={handleRemoveProcedureInput}
            isDisabled={isDisabled}
            favourite={favourite}
            updateFavourite={updateFavourite}
          />
        );
      } else {
        return null;
      }
    });
  }, [
    procedureInputs,
    procedures,
    handleSelectProcedure,
    clearSearch,
    handleDeleteProcedure,
    handleRemoveProcedureInput,
    filteredProcedureInputs,
    filteredProceduresLength,
    favourite,
    updateFavourite,
  ]);

  /* This function is used to handle the addition of a new Procedures input to the procedureInputs array when the "Add More" button is clicked.*/
  const handleAddMoreProcedureInput = () => {
    AddMoreInputs(setProcedureInputs, procedureInputs, filteredProcedureInputsLength, t("Procedure"), MAX_NUM_OF_PROCEDURES);
  };

  /** Handle updating doctor notes*/
  const handleDoctorNoteChange = (e) => {
    setDoctorNote(e.target.value);
  };

  /* Create Encounter */
  const handleSubmitEncounter = () => {
    setDisableSubmitted(true)
    const encounterData = {
      patient: appCtx.selectedUser.id,
      insurance: insurance === 1? appCtx.selectedUser.primary_insurance : appCtx.selectedUser.secondary_insurance ,
      title: `encounter for patient: ${appCtx.selectedUser.user.name}`,
      description: `encounter submitted by provider: ${authCtx.userProfile.user.name}`,
      notes: doctorNote,
      encountercpts: filteredProcedures,
      encountericds: filteredDiagnoses,
      encounterdrugs: filteredTreatmentInputsValue,
    };
    operation(
      {
        method: 'POST',
        url: urls.CREATE_NEW_ENCOUNTER,
        headers: { Authorization: `token ${authCtx.token}` },
        data: encounterData,
      },
      handleSetNewEncounter,
      handleEncounterError
    );
  };

  const handleSetNewEncounter = (newEncounterResponse) => {
    if(validateAnEncounter(newEncounterResponse)) {
      setNewEncounter(newEncounterResponse)
      appCtx.handleReFetchChartsData()
    } else {
      setDisableSubmitted(false)
      console.log('invalid new encounter response')
      Toastify({message: 'invalid new encounter response', state: toastify_status.ERROR})
    }
   
  }

  const handleEncounterError = (error) => {
    setDisableSubmitted(false)
    if(error.response.data) {
      setNewEncounterError(error.response.data)
      const errors = error.response.data.errors
      errors.map((error) => {
      const attribute = error.attr?.split('.')[2] || ''
      const detail = error.detail
      return Toastify({ message: attribute + ' ' + detail, state: toastify_status.ERROR })
   })
    } else {
      return Toastify({ message: http_response_messages.ERROR, state: toastify_status.ERROR })
    }
  }

  useEffect(() => {
    if (newEncounter) {
      Toastify({message: http_response_messages.CREATE_ENCOUNTER, state: toastify_status.SUCCESS})
      const path = `${app_paths.PATIENT_PROFILE.replace(':patientID', appCtx.selectedUser.id)}`;
      navigate(path);
    }
  }, [newEncounter, navigate, appCtx.selectedUser.id]);

  const handleClearForm = () => {
    setDiagnoses([]);
    setDiagnoseInputs(INITIAL_DIAGNOSIS_INPUT);
    setProcedures([]);
    setProcedureInputs(INITIAL_PROCEDURES_INPUT);
    setClearSearch((prevValue) => !prevValue);
    setTreatmentInputsValue([{ ...INITIAL_TREATMENT_INPUT_VALUE[0] }]);
    setTreatmentInputs(INITIAL_TREATMENT_INPUT);
    setInsurance(1);
    setDoctorNote("");
    // setTreatmentInputsIndexValidationErrors([]) ---> related to disabled submit button
  };

  useEffect(() => {
    handleClearForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appCtx.selectedUser.id]);

  // const treatmentsValidation = () => { ---> related to disabled submit button based on treatmentsValidation
  //   const flag = treatmentInputsValue.map ( (tV) => {
  //     if (!!tV) {
  //       return (tV.frequency > 0 &&
  //       tV.drug_dose &&
  //       tV.dosage_form &&
  //       tV.duration > 0 &&
  //       tV.claimed_quantity > 0 &&
  //       tV.drug_id &&
  //       true)
  //     } else {
  //       return true
  //     }
  //   })

  //   if (treatmentInputsValue.length === 0){
  //     return false
  //   }
  //   else {
  //     return flag.every(v => v === true);
  //   }
  // }

  useEffect(() => {
    const hasDiagnoses = filteredDiagnoses.length > 0;
    const hasProcedures = filteredProcedures.length > 0;
    const hasTreatments = filteredTreatmentInputsValue.length > 0
    // const treatmentValidation = treatmentsValidation(); ---> related to disabled submit button based on treatmentsValidation

    //the submit will be true only if the specified condition is met.
    const submit =
      (hasDiagnoses && hasProcedures && hasTreatments) ||
      (hasDiagnoses && hasProcedures && !hasTreatments ) ||
      (hasDiagnoses && !hasProcedures && hasTreatments)

    // Set the opposite boolean value of the submit condition to disable the submit button.
    setDisableSubmit(!submit);
    // eslint-disable-next-line
  }, [filteredDiagnoses, filteredProcedures, filteredTreatmentInputsValue]);

  const handleInsuranceChange = (event) => {
    event.preventDefault();
    setInsurance (event.target.value)
  }

  return (
    <>
    {typeof appCtx.selectedUser.user !== 'undefined' ?
    <Box sx={classes.form_container}>

      <Box sx={classes.patient_info_box}>
        <Typography sx={classes.patient_info_text}>
          {t("SelectPatientInsurance")}
        </Typography>
        <Select value={insurance} sx={classes.patient_insu_select} onChange={handleInsuranceChange}>
          <MenuItem sx={classes.patient_insu_menu} value={1}>{appCtx.selectedUser.primary_insurance_name}</MenuItem>
          {appCtx.selectedUser.primary_insurance !== appCtx.selectedUser.secondary_insurance && 
            <MenuItem sx={classes.patient_insu_menu} value={2}>{appCtx.selectedUser.secondary_insurance_name}</MenuItem>
          }
        </Select>
      </Box>

      <Box sx={classes.inputs_container}>
        {DiagnosesSelectInputs}
        <Button
          disabled={filteredDiagnoseInputsLength >= MAX_NUM_OF_DIAGNOSES}
          variant="contained"
          sx={classes.add_more}
          onClick={handleAddMoreDiagnoseInput}
        >
          {t("AddMoreDiagnosis")}
        </Button>
      </Box>

      {userRole === 'doctor' && company !== companiesObject.speedyRecovery && (
        <Box sx={classes.inputs_container}>
        {ProceduresSelectInputs}
        <Button
          disabled={filteredProcedureInputsLength >= MAX_NUM_OF_PROCEDURES}
          variant="contained"
          sx={classes.add_more}
          onClick={handleAddMoreProcedureInput}
        >
          {t("AddMoreProcedure")}
        </Button>
      </Box>
      )}

      <Box sx={classes.inputs_container}>
        {TreatmentInputsForm}
        <Button
          variant="contained"
          sx={classes.add_more}
          onClick={handleAddMoreTreatmentInputs}
          disabled={filteredTreatmentInputsLength >= MAX_NUM_OF_TREATMENTS}
        >
          {t("AddMoreTreatment")}
        </Button>
      </Box>
      <Box sx={classes.inputs_container}>
        <div style={{width:'100%'}}>
          <label htmlFor="DoctorNote">{t("Notes")}</label>
          <textarea
            style={{width:'100%', padding: '10px',  fontSize:18, outlineColor: "grey"}}
            name="DoctorNote"
            id="DoctorNote"
            rows={5}
            // placeholder="..."
            onChange={handleDoctorNoteChange}
          />
          </div>
      </Box>
      <Box sx={classes.form_actions}>
        <Button
          variant="contained"
          sx={classes.submit}
          onClick={handleSubmitEncounter}
          // disabled={disableSubmit || disableSubmitted || treatmentInputsIndexValidationErrors.length > 0} ---> related to disabled submit button based on treatmentInputsIndexValidationErrors
          disabled={disableSubmit || disableSubmitted}
        >
          {t("Submit")}
        </Button>
        <Button variant="contained" sx={classes.clear} onClick={handleClearForm}>
          {t("Clear")}
        </Button>
      </Box>
    </Box>
        :
        <CircularProgress sx={classes.circular}/>
        }
        </>
  );
};

export default NewEncounterForm;
