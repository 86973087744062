import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EncounterCardStyle from './EncounterCard.module';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { validateMreMessages } from '../../../Utils/validateMedicalCodeMessages';
import { globalColors } from '../../../constants/ColorsPalette';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const renderIcdActionMessages = ({ actionMessages }) => {
  const classes = EncounterCardStyle();

  if (validateMreMessages(actionMessages)) {
    const state = actionMessages["status"]["state"];
    if (state === 'approved' && !actionMessages["status"]["messages"]["extra_msgs"]) {
      return null;
    } else if (state === 'approved' || state === 'rejected') {
      const messageTypes = {
              'extra_msgs': {
                title: 'Additional Information',
                icon: <InfoOutlinedIcon sx={[classes.message_icon, {color: globalColors.gray}]} />,
                bgColor: globalColors.gainsboro,
                borderColor: globalColors.gray,
              },
            };

      const objectKeys = Object.keys(actionMessages['status']['messages'])
      // map throw action messages.status.messages wich is object contain list of messages, to get the keys of this messages
      // and the result like ["extra_msgs"]
      return actionMessages?.status?.messages &&
       Object.values(actionMessages.status.messages).some(msgs => Array.isArray(msgs) && msgs.length > 0) && (
        <Box sx={classes.medical_details_box}>
        <Box sx={classes.medical_details_title_box}>
            <Typography sx={classes.medical_info_title}>Medication Messages</Typography>
        </Box>
        <Box sx={classes.drug_info_value_box}>
          {objectKeys.map((key, i) => {
            return actionMessages['status']['messages'][key].length > 0 && (
              <Box key={i} sx={[classes.icd_cpt_drug_messages_container, {borderColor: messageTypes[key].borderColor}]}>
                {/* show drug action message title */}
                <Box sx={classes.medical_details_box}>
                  <Box sx={[classes.medical_details_title_box, {backgroundColor: messageTypes[key].bgColor}]}>
                    {messageTypes[key].icon}
                    <Typography sx={classes.medicalCode_message_title}>{messageTypes[key].title}</Typography>
                  </Box>
                  <Box sx={classes.medical_info_value_box}>
                    {/* show drug action message content */}
                    <Box sx={classes.icd_cpt_messages_content_box}>
                      {actionMessages['status']['messages'][key].map((msg, i) => {
                        return (
                          <Box
                            key={i}
                            sx={[
                              classes.medicalCode_messages_point,
                              {
                                color: state === 'rejected'
                                  ? globalColors.darkMaroon
                                  : globalColors.jetBlack
                              }
                            ]}
                          >
                            <Typography sx={[classes.icd_cpt_drug_message, {borderColor: messageTypes[key].borderColor}]}>{msg.msg}</Typography>
                          </Box>
                        )
                      })}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>
      )
    } else {
      // show any state that is not approved or rejected
      return <Typography sx={classes.medicalCode_message_title}>{state}</Typography>;
    }
  } else {
    return (
      <Box sx={[classes.medicalCode_messages_point, classes.error_msg]}>
        <FiberManualRecordIcon sx={classes.medicalCode_dot_icon} />
        <Typography>Message cannot be shown</Typography>
      </Box>
    );
  }
};

export default renderIcdActionMessages;
