import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EncounterCardStyle from './EncounterCard.module';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { validatePbmMessages } from '../../../Utils/validateMedicalCodeMessages';
import { globalColors } from '../../../constants/ColorsPalette';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import BiotechIcon from '@mui/icons-material/Biotech';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import PersonIcon from '@mui/icons-material/Person';

const RenderDrugActionMessages = ({ actionMessages }) => {
  const classes = EncounterCardStyle();

  if (validatePbmMessages(actionMessages)) {
    const state = actionMessages["status"]["state"];

    if (state === 'approved' || state === 'rejected') {
      const messageTypes = {
        'td_msg': {
          title: 'Therapeutic Duplications',
          icon: <SyncProblemIcon sx={[classes.message_icon, {color: globalColors.amber}]} />,
          bgColor: globalColors.beige,
          borderColor: globalColors.amber,
        },
        'dci_msg': {
          title: 'Drug Counter Indications',
          icon: <WarningAmberIcon sx={[classes.message_icon, {color: globalColors.crimson}]} />,
          bgColor: globalColors.lightPink,
          borderColor: globalColors.crimson,
        },
        'ddi_msg': {
          title: 'Drug to Drug Interactions',
          icon: <BiotechIcon sx={[classes.message_icon, {color: globalColors.indigo}]} />,
          bgColor: globalColors.lavender,
          borderColor: globalColors.indigo,
        },
        'gi_msg': {
          title: 'Gender Interactions',
          icon: <PersonIcon sx={[classes.message_icon, {color: globalColors.dodgerBlue}]} />,
          bgColor: globalColors.lightBlue,
          borderColor: globalColors.dodgerBlue,
        },
        'di_msg': {
          title: 'Drug Indications',
          icon: <LocalPharmacyIcon sx={[classes.message_icon, {color: globalColors.teal}]} />,
          bgColor: globalColors.paleTurquoise,
          borderColor: globalColors.teal,
        },
        'extra_msgs': {
          title: 'Additional Information',
          icon: <InfoOutlinedIcon sx={[classes.message_icon, {color: globalColors.gray}]} />,
          bgColor: globalColors.gainsboro,
          borderColor: globalColors.gray,
        },
      };

      const objectKeys = Object.keys(actionMessages['status']['messages'])
      // map throw action messages.status.messages wich is object contain list of messages, to get the keys of this messages
      // and the result like ["di_msg", "gi_msg", "td_msg", "dci_msg", "ddi_msg", "extra_msgs"]

      return actionMessages?.status?.messages &&
       Object.values(actionMessages.status.messages).some(msgs => Array.isArray(msgs) && msgs.length > 0) && (
        <Box sx={classes.medical_details_box}>
          <Box sx={classes.medical_details_title_box}>
              <Typography sx={classes.medical_info_title}>Medication Messages</Typography>
          </Box>
          <Box sx={classes.drug_info_value_box}>
            {objectKeys.map((key, i) => {
              return actionMessages['status']['messages'][key].length > 0 && (
                <Box key={i} sx={[classes.icd_cpt_drug_messages_container, {borderColor: messageTypes[key].borderColor}]}>
                  {/* show drug action message title */}
                  <Box sx={classes.medical_details_box}>
                    <Box sx={[classes.medical_details_title_box, {backgroundColor: messageTypes[key].bgColor}]}>
                      {messageTypes[key].icon}
                      <Typography sx={classes.medicalCode_message_title}>{messageTypes[key].title}</Typography>
                    </Box>
                    <Box sx={classes.medical_info_value_box}>
                      {/* show drug action message content */}
                      <Box sx={classes.drug_messages_content_box}>
                        {actionMessages['status']['messages'][key].map((msg, i) => {
                          return (
                            <Box
                              key={i}
                              sx={[
                                classes.medicalCode_messages_point,
                                {
                                  color: state === 'rejected'
                                    ? globalColors.darkMaroon
                                    : globalColors.jetBlack
                                }
                              ]}
                            >
                              <Typography sx={[classes.icd_cpt_drug_message, {borderColor: messageTypes[key].borderColor}]}>{msg.msg}</Typography>
                              {msg['Severity level'] && (
                                <>
                                  <PlayArrowIcon sx={classes.severity_level_icon} />
                                  <Typography sx={classes.severity_level_message}> Severity level: {msg['Severity level']}</Typography>
                                </>
                              )}
                            </Box>
                          )
                        })}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Box>
      )
    } else {
      // show any state that not approved or pending approval or rejected
      return <Typography sx={classes.medicalCode_message_title}>{state}</Typography>;
    }
  } else {
    return <Box sx={[classes.medicalCode_messages_point, classes.error_msg]}>
    <FiberManualRecordIcon sx={classes.medicalCode_dot_icon} />
    <Typography>Message cannot be shown</Typography> 
  </Box>
  }
};

export default RenderDrugActionMessages;