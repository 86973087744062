import { useState, useEffect, useRef, useContext } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import classes from './Chatbot.module.css';
import AuthContext from '../../../contexts/auth-context';
import SendIcon from '@mui/icons-material/Send';
import { chatbotCase, chatBot } from '../../config/variables';
import ReactMarkdown from 'react-markdown'; // ---> used to given a string of markdown
import { fetchSessionId, chatWithOpenAi } from '../../../api/openai';
import TypingDots from '../TypingDots/TypingDots';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Chatbot = () => {
  const [loading, setLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [keepScrolling, setKeepScrolling] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const authCtx = useContext(AuthContext);
  const gender = authCtx.userProfile.user.gender;
  const [chatHistory, setChatHistory] = useState([]); // ---> this is the chat history that is displayed to the user.
  const [userInput, setUserInput] = useState({ role: 'user', content: '' });
  const [sessionId, setSessionId] = useState(''); // ---> openai api key
  const [assistantAnswer, setAssistantAnswer] = useState('');
  const lastMessageRef = useRef(null);
  const bottomRef = useRef()

  // settings menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const openSettingsMenu = Boolean(anchorEl);

  // get session_id from ml_server
  useEffect(() => {
    fetchSessionId().then(setSessionId)
  }, [])

  const sendMessage = async () => {
    setChatHistory((preV) => [...preV, userInput ])
    setUserInput({ role: 'user', content: '' })

    setLoading(true)
    const answer = await chatWithOpenAi(sessionId, userInput.content)
    if(answer) {
      setAssistantAnswer(answer)
      setIsStreaming(true)
      setLoading(false)
      setKeepScrolling(true) // ---> will scroll down while streaming
      setTimeout(() => { // ---> scrolling will stop depend on the duration even if still streaming
        setKeepScrolling(false)
      }, chatBot.scrollDownDuration)
        setChatHistory(prev => [...prev, { role: 'assistant', content: '' }]); // ---> initial an empty assistant message to stream the ai answer
      } else {
        setLoading(false)
      }
  }

  // streaming assistant response, it will modify the last message inside chat history and tops when all characters are appended.
  useEffect(() => {
    if (assistantAnswer && isStreaming) {
      let index = -1; // ---> startign with the first character of the assistant answer

      const interval = setInterval(() => {
        // This will run every 10 milliseconds
        if (index < assistantAnswer.length - 1) {
          setChatHistory((prev) => {
            const lastMessages = [...prev];
            const lastMessageIndex = lastMessages.length - 1;

            if (lastMessages[lastMessageIndex]?.role === "assistant") {
              lastMessages[lastMessageIndex] = {
                ...lastMessages[lastMessageIndex],
                content: lastMessages[lastMessageIndex].content + assistantAnswer[index],
              };
            }

            return lastMessages;
          });

          index++; // ---> increment the index to the next character untill the last character is reached
        } else {
          clearInterval(interval);
          setIsStreaming(false);
        }
      }, chatBot.streamingDuration);

      return () => clearInterval(interval);
    }
  }, [assistantAnswer, isStreaming]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleChange = (e) => {
    if (!loading && !isStreaming) {
      setUserInput({ role: 'user', content: e.target.value });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };

  // clear chat history (This feature will be added later.)
  const clearChatHistory = () => {
    console.log('clear chat history')
  }

  // scroll down when asking a new question
  useEffect(() => {
    const lastMessage = chatHistory.slice(-1)[0] || [];
    if (lastMessageRef.current && lastMessage.role === 'user') {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [chatHistory]);

  // stop openai answer streaming
  const stopStreaming = () => {
    if(!loading) {
      setIsStreaming(false)
    }
  }

  // scroll down for a while
  useEffect(() => {
    if(keepScrolling) {
      bottomRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [chatHistory, keepScrolling])

  const handleClickSettingsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSettingsMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        className={`${classes.card_container} ${isOpen && classes.card_container_open}`}
      >
        <div className={classes.card_header}>
          <div className={classes.avatar_title}>
            <img
              className={classes.header_avatar}
              src="/images/chatbot.png"
              alt="Chatbot"
            />
            <h1 className={classes.title}>Veritas Pulse Assistant</h1>
          </div>
          <div style={{}}>
            <Tooltip title="Settings">
              <MoreHorizIcon className={classes.icon} onClick={handleClickSettingsMenu} />
            </Tooltip>

            <Tooltip title="Minimize">
              <RemoveIcon className={classes.icon} onClick={handleToggle} />
            </Tooltip>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openSettingsMenu}
              onClose={handleCloseSettingsMenu}
              className={classes.menu}
              MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
              <div className={classes.menu_container}>
                <MenuItem className={classes.menu_item} onClick={clearChatHistory}>
                  <ClearAllIcon className={classes.menu_icon} />
                  Clear chat
                </MenuItem>
              </div>
            </Menu>
          </div>

        </div>
        <div className={`${classes.chat_container} ${keepScrolling ? classes.disabled : ''}`}>
          {chatHistory.map((message, index) => (
            <div
             key={index}
             ref={index === chatHistory.length - 1 ? lastMessageRef : null}
             className={`${classes.message_box} ${
               message.role === 'assistant'
                 ? classes.bot_message_box
                 : classes.user_message_box
             }`}
           >
             <img
               src={
                 message.role === "user"
                   ? gender === "F"
                     ? "/images/patient_woman.svg"
                     : "/images/patient_man.svg"
                   : "/images/chatbot.png"
               }
               alt={message.role === "user" ? "User" : "Chatbot"}
               className={`${classes.message_avatar} ${
                 message.role === 'assistant'
                   ? classes.bot_message_avatar
                   : classes.user_message_avatar
               }`}
             />
             {message.role === 'assistant' ? (
                 <div className={`${classes.message} ${classes.bot_message}`}>
                   <ReactMarkdown>
                     {message.content}
                   </ReactMarkdown>
                 </div>
               ) : (
                 <p className={`${classes.message} ${classes.user_message}`}>
                   {message.content}
                 </p>
               )}
           </div>
          ))}
          <div ref={bottomRef}></div>
          {loading && (
            <div className={classes.loading_message_box}>
              <img
                src="/images/chatbot.png"
                alt="Chatbot"
                className={classes.loading_message_avatar}
              />
              <TypingDots/>
            </div>
          )}
        </div>

        <div className={classes.bottom}>
          <div className={classes.input_box}>
            <textarea
              className={classes.input}
              placeholder="Ask something."
              value={userInput.content}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />

            {loading || isStreaming ? (
                <StopCircleIcon
                  className={`${classes.submit} ${loading ? classes.disabled : ''}`}
                  onClick={stopStreaming}
                />
            ) : (
                <SendIcon
                  className={classes.submit}
                  onClick={sendMessage}
                />
            )}
          </div>
        </div>
      </div>

      {!isOpen && (
        <div
          className={`${classes.bot_avatar_box} ${
            !chatbotCase && classes.bot_avatar_box_hide
          }`}
        >
          <img
            className={classes.bot_avatar}
            src="/images/chatbot.png"
            alt="Chatbot"
            onClick={handleToggle}
          />
        </div>
      )}
    </>
  );
};


export default Chatbot;