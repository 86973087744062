const EncounterCardStyle = () => {
	return {
		accept_reject_box: {
			display: 'flex',
			flexDirection: 'row',
			gap: '1px',
			marginTop: '2px'
		},
		accept_reject_form: {
			width: '100px',
			height: '50px'
		},
		accept_reject_input: {
			backgroundColor: "#fff",
			width: '190px'
		},
		accept_reject_button: {
			fontSize: '10px',
			fontWeight: '600',
			padding: '1px 7px',
			maxWidth: '50px',
			marginLeft: '5px'
		},
		accept_accordion: {
			color: '#0f2e11',
			background: '#b5dbb5',
			marginTop: '16px',
			'&: hover': {
				background: '#aad9aa',
			},
		},
		reject_accordion: {
			background: '#f5cece',
			color: '#5f2120',
			marginTop: '16px',
			'&: hover': {
				background: '#ebbebe',
			},
		},
		partially_accept_accordion: {
			background: '#fdd8ab',
			color: '#ae6200',
			marginTop: '16px',
			'&: hover': {
				background: '#fdce95',
			},
		},
		pending_approval_accordion: {
			background: '#d4d4d4',
			color: '#333',
			marginTop: '16px',
			'&:hover': {
				background: '#CACACA',
			},
		},
		info_text: {
			fontWeight: '500',
			marginBottom: '5px',
			textTransform: 'capitalize',
			color: '#1E201E'
		},
		pointer_info_text: {
			cursor: 'pointer',
			display: 'inline-block',
			color: '#5272F2',
			borderBottom: '1px solid #5272F2',
			marginLeft: '10px'
		},
		encounter_accordion_header_info_box: {
			marginBottom: '10px',
			padding: '10px',
			borderRadius: '10px'
		},
		encounter_accordion_header_info_box_approved: {
			backgroundColor: '#EEF8EF'
		},
		encounter_accordion_header_info_box_rejected: {
			backgroundColor: '#F8E8E8'
		},
		encounter_accordion_header_info_box_partially_approved: {
			backgroundColor: '#FFE8B6'
		},
		section_container: {
			display: 'flex',
			flexDirection: 'column',
			'@media (min-width: 960px)': {
				flexDirection: 'row',
				flexWrap: 'wrap',
				gap: '24px',
			},
		},
		section_column: {
			flex: 1,
			minWidth: '280px',
			'@media (min-width: 960px)': {
				flexBasis: 'calc(50% - 12px)',
			},
		},
		encounter_accordion_summary: {
			display: 'flex',
			alignItems: 'center',
			width: '100%'
		},
		encounter_accordion_summary_date_box: {
			display: 'flex',
			alignItems: 'center'
		},
		encounter_accordion_summary_date_icon: {
			fontSize: '20px'
		},
		encounter_accordion_summary_date_date: {
			marginLeft: '10px',
			fontSize: '20px',
			fontWeight: '500'
		},
		encounter_accordion_summary_state_box: {
			marginLeft: '15px',
			padding: '4px 10px',
			borderRadius: '10px',
			fontWeight: '500'
		},
		summary_approved_state_bg: {
			backgroundColor: '#8DB78F'
		},
		summary_rejected_state_bg: {
			backgroundColor: '#D7A9AB'
		},
		summary_partially_approved_state_bg: {
			backgroundColor: '#F5BA97'
		},
		encounter_accordion_summary_patient_box: {
			display: 'flex',
			alignItems: 'center',
			marginLeft: 'auto'
		},
		encounter_accordion_summary_patient_icon: {
			fontSize: '20px'
		},
		encounter_accordion_summary_patient_patient: {
			marginLeft: '10px',
			fontSize: '20px',
			fontWeight: '500'
		},

		medical_accordion_title: {
			fontWeight: '600',
			borderBottom: 'none',
			display: 'flex',
			alignItems: 'center',
			margin: '10px 0',
		},

		medical_accordion: {
			backgroundColor: 'white',
			borderRadius: '8px',
			marginBottom: '16px',
			overflow: 'hidden',
			border: '1px solid',
			boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)',
			'&:before': {
				display: 'none'
			},
		},
		medical_accordion_approved_border: {
			borderColor: 'rgba(46, 125, 50, 0.2)'
		},
		medical_accordion_rejected_border: {
			borderColor: 'rgba(211, 47, 47, 0.2)'
		},
		medical_accordion_summary: {
			padding: '12px 16px',
			'& .MuiAccordionSummary-content': {
				margin: 0
			},
		},
		medical_approved_accordion_summary_bg: {
			backgroundColor: 'rgba(224, 242, 224, 0.5)'
		},
		medical_rejected_accordion_summary_bg: {
			backgroundColor: 'rgba(243, 205, 205, 0.5)'
		},
		medical_summary_state_box: {
			display: 'flex',
			alignItems: 'center',
			width: '100%'
		},
		medical_summary_info_box: {
			display: 'flex',
			flexGrow: 1,
			alignItems: 'center'
		},
		medical_icon: {
			fontSize: 20,
			marginRight: 1.5,
		},
		column_box: {
			display: 'flex',
			flexDirection: 'column'
		},
		medical_code: {
			fontWeight: 700,
			fontSize: '16px',
			marginRight: '8px'
		},
		medical_approved_code_color: {
			color: '#1B5E20'
		},
		medical_rejected_code_color: {
			color: '#5f2120'
		},
		medical_primary: {
			backgroundColor: 'rgba(25, 118, 210, 0.1)',
			color: '#1976D2',
			fontWeight: '500',
			fontSize: '12px',
			height: '20px',
			marginRight: '8px',
			border: '1px solid rgba(25, 118, 210, 0.2)',
		},
		align_icd_primary: {
			display: 'flex',
			alignItems: 'baseline'
		},
		mediacl_description: {
			fontWeight: 400,
			fontSize: '14px',
		},
		mediacl_description_approved_color: {
			color: '#2E7D32'
		},
		mediacl_description_rejected_color: {
			color: '#743a38'
		},
		medical_accordion_details: {
			padding: '16px'
		},
		medical_details_box: {
			marginBottom: '16px',
			backgroundColor: 'white',
			borderRadius: '6px',
			overflow: 'hidden',
			boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)',
		},
		medical_details_title_box: {
			display: 'flex',
			alignItems: 'center',
			backgroundColor: 'rgba(0, 0, 0, 0.04)',
			padding: '8px 12px',
			borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
		},
		medical_info_title: {
			fontSize: '13px',
			fontWeight: '600',
			color: '#424242',
			textTransform: 'uppercase',
			letterSpacing: '0.5px'
		},
		medical_info_value_box: {
			display: 'grid',
			gridTemplateColumns: {
				xs: '1fr',
				sm: 'repeat(4, 1fr)'
			},
			gap: '12px',
			padding: '12px 16px'
		},
		drug_info_value_box: {
			gridTemplateColumns: {
				xs: '1fr',
				sm: 'repeat(4, 1fr)'
			},
			gap: '12px',
			padding: '12px 16px'
		},
		medical_info_value_label: {
			fontSize: '12px',
			fontWeight: '500',
			color: '#757575',
			marginBottom: '4px'
		},
		medical_info_value_values: {
			fontSize: '14px',
			fontWeight: '500',
		},

		infoCard: {
			marginBottom: '16px',
			backgroundColor: 'white',
			borderRadius: '6px',
			overflow: 'hidden',
			boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)',
		},
		cardHeader: {
			backgroundColor: 'rgba(0, 0, 0, 0.04)',
			padding: '8px 12px',
			borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
		},
		cardHeaderText: {
			fontSize: '13px',
			fontWeight: '600',
			color: '#424242',
			textTransform: 'uppercase',
			letterSpacing: '0.5px',
		},
		infoGrid: {
			display: 'grid',
			gridTemplateColumns: {
				xs: '1fr',
				sm: 'repeat(4, 1fr)'
			},
			gap: '12px',
			padding: '12px 16px',
		},
		infoGridPrescription: {
			display: 'grid',
			gridTemplateColumns: {
				xs: '1fr',
				sm: 'repeat(3, 1fr)'
			},
			gap: '12px',
			padding: '12px 16px',
		},
		infoItem: {
			display: 'flex',
			flexDirection: 'column',
		},
		label: {
			fontSize: '14px',
			fontWeight: '550',
			color: 'black',
			marginBottom: '4px',
		},
		value: {
			fontSize: '14px',
			fontWeight: '500',
			color: '#212121',
		},
		quantityCard: {
			marginBottom: '12px',
			backgroundColor: 'white',
			borderRadius: '6px',
			overflow: 'hidden',
			boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)',
		},
		encounter_title: {
			fontWeight: '500',
			color: '#1E201E'
		},


		dot_icon: {
			fontSize: '14px',
			marginRight: '4px',
		},
		check_icon: {
			fontSize: '22px',
			marginRight: '4px',
			color: 'green'
		},
		close_icon: {
			fontSize: '22px',
			marginRight: '4px',
			color: 'red'
		},
		medicalCode_dot_icon: {
			fontSize: '8px',
			marginRight: '4px',
		},

		medicalCode_message_title: {
			paddingLeft: '0px',
			color: '#1E201E',
			fontWeight: '500',
			marginLeft: '5px'
		},
		medicalCode_message: {
			fontWeight: '450'
		},

		medicalCode: {
			fontWeight: '500'
		},
		medicalCode_messages_point: {
			display: 'flex',
			alignItems: 'center',
			marginLeft: '6px',
			marginBottom: '10px'
		},
		circular: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		},
		accepted_reload: {
			color: '#006400'
		},
		partially_accept_reload: {
			color: '#333333'
		},
		rejected_reload: {
			color: '#800000'
		},
		error_msg: {
			color: 'red'
		},

		drug_info: {
			fontWeight: '430',
			marginRight: '10px'
		},
		reloadBox: {
			display: 'flex',
			justifyContent: 'flex-end',
			marginTop: '10px'
		},
		reloadBtn: {
			backgroundColor: 'red',
			color: 'white',
			padding: '4px 12px',
			margin: '0 10px',
			borderRadius: '4px',
			cursor: 'pointer',
			letterSpacing: '1.5px',
			display: 'flex'
		},
		reloadIcon: {
			fontSize: '20px',
			marginRight: '5px'
		},
		btn_accepted_reload: {
			backgroundColor: '#074173'
		},
		btn_partially_accept_reload: {
			backgroundColor: '#333333'
		},
		btn_rejected_reload: {
			backgroundColor: '#800000'
		},
		insurance_save_button: {
			float: 'left',
			fontSize: '10px',
			fontWeight: '600',
			backgroundColor: 'grey',
			margin: '10px 7px',
			maxWidth: '50px'
		},
		icd_cpt_drug_messages_container: {width: '100%', borderLeft: `4px solid`, borderRadius: '8px'},
		icd_cpt_messages_content_box: {width: '550px'},
		icd_cpt_drug_message: {borderLeft: `3px solid`, color: 'black', fontWeight: '450', paddingLeft: '5px'},
		drug_messages_content_box: {width: '1200px'},
		severity_level_icon: {color: 'black'},
		severity_level_message: {color: 'black', fontWeight: '450'},
		message_icon: {fontSize: '22px'}
	};
};

export default EncounterCardStyle;