const DEFAULT_BASE_URL = 'http://40.172.139.118'
const DEFAULT_PAGE_SIZE = 10

const ml_base_url = 'https://speedyrecovery-scs.com'

// const DEFAULT_BACKEND_PORT = 8000
const DEFAULT_ML_BACKEND_PORT = 8443

// our backend port
// const backend_port = process.env.REACT_APP_BACKEND_PORT
//  ? process.env.REACT_APP_BACKEND_PORT
//  : DEFAULT_BACKEND_PORT

 // machine learning backend port
const ml_backend_port = process.env.REACT_APP_ML_BACKEND_PORT
? process.env.REACT_APP_ML_BACKEND_PORT
: DEFAULT_ML_BACKEND_PORT

// Shared Key Authentication is a custom authentication mechanism
// It ensures that only clients with a valid shared key can access the endpoints.
// in our case the endpoint is (rag_tools/get-openai-api-key/) to get open api key and tools
const DEFAULT_ML_SERVER_SHARED_KEY = '1234'
export const ML_MODEL = 'gpt-4o'

export const APP_ML_SERVER_SHARED_KEY =
  process.env.REACT_APP_ML_SERVER_SHARED_KEY
  ? process.env.REACT_APP_ML_SERVER_SHARED_KEY
  : DEFAULT_ML_SERVER_SHARED_KEY;


// const getChatbotCase = () => {
//   if (process.env.REACT_APP_CHATBOT_CASE && process.env.REACT_APP_CHATBOT_CASE === 'true') {
//     return true
//   } else {
//     return false
//   }
// }
// export const chatbotCase = getChatbotCase()
export const chatbotCase = true;


const logoPaths = {
  cloudKair: '',
  veritas: "/images/logo/veritas/black/full-black.png",
  sehteqArabia: '',
  speedyRecovery: "/images/logo/speedy-recovery/transparent-logo.png"
}
// to change the companiens name, make sure to update the env file.
export const companiesObject = {
  cloudKair: 'cloudKair',
  veritas: 'veritas',
  sehteqArabia: 'sehteqArabia',
  speedyRecovery: 'speedyRecovery'
}

export const company = process.env.REACT_APP_COMPANY_NAME ? process.env.REACT_APP_COMPANY_NAME : companiesObject.veritas

export const appLogoPath = logoPaths[company]

// our backend urls
export const urls = {
  BASE_URL: `${process.env.REACT_APP_BASE_URL 
  ? process.env.REACT_APP_BASE_URL 
  : DEFAULT_BASE_URL}/`,
  
  LOGGED_USER: '/api/user/me',

  CREATE_A_PATIENT: '/api/user/patientprofile/create',
  SEARCH_FOR_PATIENT: '/api/user/patientprofiles?searchfor=',
  GET_A_PATIENT: '/api/user/patientprofile/',
  LIST_PATIENTS: '/api/user/patientprofiles',

  LIST_DIAGNOSES: '/api/medical/codes/icds',
  LIST_SEARCHED_DIAGNOSES: 'api/medical/codes/icds/search',
  LIST_SEARCHED_PROCEDURES: 'api/medical/codes/cpts/search',
  LIST_SEARCHED_DRUGS: 'api/medical/codes/drugs/search',

  GET_USER_ENCOUNTERS: '/api/patient/encounter/patientid/',
  CREATE_NEW_ENCOUNTER: '/api/patient/encounter/create/',
  LIST_ENCOUNTERS: '/api/patient/encounters/',
  GET_ENCOUNTER: '/api/patient/encounter',
  UPDATE_GET_ENCOUNTER: '/api/patient/encounter/',
  ANALYSIS_ENCOUNTERS:'/api/analysis/encounter_state',
  DOCTOR_PRICES:'/api/analysis/doctor_prices',
  DRUGS_COUNT:'/api/analysis/most_used_drugs',
  ICDS_COUNT:'/api/analysis/most_used_icds',

  LIST_INSURANCES: '/api/insurance/insurances',
  LIST_FACILITIES:'/api/facility/facilities',
  
  UPLOAD_MRE: '/api/insurance/insurance/files/mreupload',
  UPLOAD_ICD_PRICES: '/api/insurance/insurance/files/icdupload',
  UPLOAD_CPT_PRICES: '/api/insurance/insurance/files/cptupload',
  UPLOAD_DRUG_PRICES: '/api/insurance/insurance/files/drugupload',

  GET_PATIENT_Diagnosis: '/api/patient/encounter/icd/patientid/',
  GET_PATIENT_Procedures: '/api/patient/encounter/cpt/patientid/',
  GET_PATIENT_Medications: '/api/patient/encounter/drug/patientid/',

  ALTERNATIVE_DRUG_OPTIONS: '/api/medical/codes/drugs/drugalternatives/',
  CHANGE_PASSWORD: '/api/user/user/change_password',
  GENERATE_INVOICE: '/api/erpnext/generate_invoice',

  FAVOURITE : '/api/user/user/favourite/',
};

// machine learning urls
export const ml_urls = {
  BASE_URL: `${ml_base_url}`
  + ":" + ml_backend_port + '/',
  SESSION_ID: 'api/chat/initialize/',
  MESSAGE: 'api/chat/message/:sessionId/', // ---> replace sessionId with the actual ID
 }

export const MAX_NUM_OF_DIAGNOSES = 10;
export const MIN_NUMBER_OF_SEARCH = 3;
export const MAX_NUM_OF_PROCEDURES = 10;
export const INITIAL_TREATMENTS_INPUT = 1;
export const MAX_NUM_OF_TREATMENTS = 10;
export const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE ? process.env.REACT_APP_PAGE_SIZE : DEFAULT_PAGE_SIZE;
export const ENCOUNTER_SLICE_LENGTH = 5;

export const INITIAL_DIAGNOSIS_INPUT = [
  {
    index: 0,
    label: 'Primary Diagnosis',
    name: 'primary',
  },
  {
    index: 1,
    label: 'Secondary Diagnosis',
    name: 'secondary',
  },
];
export const INITIAL_PROCEDURES_INPUT = [
  {
    index: 0,
    label: 'Procedure 1',
    name: 'Procedure 0',
  },
];

export const INITIAL_TREATMENT_INPUT = [
  {
    index: 0,
    label: 'Treatment 1',
  },
];

export const INITIAL_TREATMENT_INPUT_VALUE = [
  {
    drug_dose: '',
    duration: '',
    frequency: '',
    drug_id: '',
    dosage_form: '',
    claimed_quantity: ''
  },
];

export const INITIAL_PATIENT_INSURANCE = {
  id: 1,
  name: "Out of Pocket",
  description: "Out of Pocket",
  register_id: "oop",
  use_mre: false
}

export const ENCOUNTER_ACTIONMSG = {
  Approved: 'Approved',
  Rejected: 'Rejected',
  Partially_accepted: 'Partially accepted',
  Pending_approval: 'Pending approval',
}

export const app_paths = {  
  LOGIN_AUTH : '/login',
  NEW_PATIENT: '/new-patient',
  UPDATE_PATIENT: '/update-patient/:patientID',
  NEW_ENCOUNTER: '/new-encounter/:patientID',
  PATIENT_PROFILE: '/patient/:patientID',
  DOCTOR_PROFILE: '/doctor/:doctorName',
  INSURANCE_ANALYSIS: '/insurance-analysis-page',
  SETTINGS: '/settings',
  INSURANCE_SETTINGS: '/insurance-settings',
  MY_ENCOUNTERS: '/my-encounters',
}

export const reload_encounter = {
    FREQUENCY: 5000,
    DURATION: 120000
}

export const http_response_messages = {
  CREATE_ENCOUNTER: 'Encounter Created Successfuly',
  UPDATE_ENCOUNTER: 'Encounter is Updated',
  CREATE_PATIENT: 'Patient Added Successfully',
  UPDATE_PATIENT: 'Patient Updated Successfully',
  CHANGE_PASSWORD: 'Password Changed Successfully',
  ERROR: 'something went wrong!'
}

export const toastify_status = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info'
}

export const searchByStateButtons = [
  {
    name: 'Approved',
    color: 'success'
  },
  {
    name: 'Partially accepted',
    color: 'warning'
  },
  {
    name: 'Rejected',
    color: 'error'
  },
  {
    name: 'Canceled',
    color: 'info'
  },
]

export const patientFormType = {
  create: 'create',
  update: 'update'
}

export const SearchBarMenuItems = ({t, userRole, hide}) => {
  const menuItems = [
    {
      key: 1,
      label: t('Search.Date'),
      value: 'date',
      itemRoles: ['doctor', 'insurance_admin', 'facility_admin', 'patient', 'pharmacy_admin'],
      itemCompany: [companiesObject.veritas, companiesObject.speedyRecovery],
    },
    {
      key: 2,
      label: t('Search.ProviderName'),
      value: 'provider_name',
      itemRoles: ['insurance_admin', 'facility_admin', 'patient', 'pharmacy_admin'],
      itemCompany: [companiesObject.veritas, companiesObject.speedyRecovery],
    },
    {
      key: 3,
      label: t('Search.PatientName'),
      value: 'patient_name',
      itemRoles: ['insurance_admin', 'facility_admin', 'pharmacy_admin'],
      itemCompany: [companiesObject.veritas, companiesObject.speedyRecovery],
    },
    {
      key: 4,
      label: t('Search.ICDCode'),
      value: 'icd_code',
      itemRoles: ['doctor', 'insurance_admin', 'facility_admin', 'patient', 'pharmacy_admin'],
      itemCompany: [companiesObject.veritas, companiesObject.speedyRecovery],
    },
    {
      key: 5,
      label: t('Search.CPTCode'),
      value: 'cpt_code',
      itemRoles: ['doctor', 'insurance_admin', 'facility_admin', 'patient'],
      itemCompany: [companiesObject.veritas],
    },
    {
      key: 6,
      label: t('Search.DrugName'),
      value: 'drugname',
      itemRoles: ['doctor', 'insurance_admin', 'facility_admin', 'patient', 'pharmacy_admin'],
      itemCompany: [companiesObject.veritas, companiesObject.speedyRecovery],
    },
    {
      key: 7,
      label: t('Search.State'),
      value: 'state',
      itemRoles: ['doctor', 'insurance_admin', 'facility_admin', 'patient', 'pharmacy_admin'],
      itemCompany: [companiesObject.veritas, companiesObject.speedyRecovery],
    },
  ];

  const filteredMenuItems = menuItems.filter(
    (item) => item.itemCompany.includes(company) && item.itemRoles.includes(userRole) && !item.value.includes(hide)
  );

  return filteredMenuItems;
};

export const sliderImages = () => {
  const images = [
    { url: 'images/sliderImages/image1.png', usedCompany: [companiesObject.veritas, companiesObject.speedyRecovery] },
    { url: 'images/sliderImages/image2.png', usedCompany: [companiesObject.veritas] },
    { url: 'images/sliderImages/image3.png', usedCompany: [companiesObject.veritas, companiesObject.speedyRecovery] },
  ];

  const filteredSliderImages = images.filter((image) => image.usedCompany.includes(company))
  return filteredSliderImages
}

export const pricesFileIndicators = () => {
  const indicators = [
    {value:"icd_file", label:"ICD File", usedCompany: [companiesObject.veritas, companiesObject.speedyRecovery]},
    {value:"cpt_file", label:"CPT File", usedCompany: [companiesObject.veritas]},
    {value:"drug_file", label:"DRUG File", usedCompany: [companiesObject.veritas, companiesObject.speedyRecovery]},
  ]

  const filteredIndicators = indicators.filter((indicator) => indicator.usedCompany.includes(company))
  return filteredIndicators
}

export const chatBot = {
  scrollDownDuration: 5000, // ---> stop scrolling down after 5 second when openai is streaming the answer
  streamingDuration: 10 // ---> streaming assiatant answer every 10 milliseconds
}
