import axios from 'axios';
import { ml_urls, toastify_status } from "../components/config/variables";
import { validateOpenAiSessionId, validateOpenAiAssistantResponse } from '../Utils/validateHttpResponse';
import Toastify from '../components/ui/Toastify';

export const fetchSessionId = async () => {
  try {
    const url = `${ml_urls.BASE_URL}${ml_urls.SESSION_ID}`;
    const response = await axios.post(url, {
      headers: { 'Content-Type': 'application/json' }
    });
    if(validateOpenAiSessionId(response)) {
      return response.data.session_id
    } else {
      Toastify({message: 'invalid Open Ai session id', state: toastify_status.ERROR})
    }
  } catch (error) {
    Toastify({message: 'Something went wrong, please try again later.', state: toastify_status.ERROR})
  }
};

export const chatWithOpenAi = async (sessionId, userMessage) => {
  try {
    const url = `${ml_urls.BASE_URL}${ml_urls.MESSAGE.replace(':sessionId', sessionId)}`;
    const response = await axios.post(url, {"message": userMessage}, {
      headers: { 'Content-Type': 'application/json' }
    });

    if(validateOpenAiAssistantResponse(response)) {
      return response.data.response
    } else {
      Toastify({message: 'invalid Open Ai Assistant response data', state: toastify_status.ERROR})
    }

  } catch (error) {
    Toastify({message: 'Something went wrong, please try again later.', state: toastify_status.ERROR})
  }
};