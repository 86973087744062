
const TypingDotsStyle = () => {
  return {
    dot: {
        width: '8px',
        height: '8px',
        backgroundColor: '#1D1616',
        borderRadius: '50%',
      },
    dot_animation: {
        opacity: [0.3, 1, 0.3],
    },
  };
};

export default TypingDotsStyle;
