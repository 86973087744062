export const globalColors = {
      jetBlack: '#1E201E',
      darkMaroon: '#721c24',
      greenPea: "#399918",
      red: '#FF0000',
      beige: '#FFFDF7',
      amber: '#F57C00',
      lightPink: '#FFF7F7',
      crimson: '#D12424',
      lavender: '#F9F7FF',
      indigo: '#562AAD',
      lightBlue: '#F3F9FF',
      dodgerBlue: '#0D6FD0',
      paleTurquoise: '#E3FEF7',
      teal: '#135D66',
      gainsboro: '#FBFBFB',
      gray: '#999999',
  }