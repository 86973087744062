import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../contexts/app-context';
import AuthContext from '../../../contexts/auth-context';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EncounterCardStyle from './EncounterCard.module';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import 'reactjs-popup/dist/index.css';
import useAxios from '../../../hooks/useAxios';
import { urls, app_paths, ENCOUNTER_ACTIONMSG, reload_encounter, toastify_status } from '../../config/variables';
import CircularProgress from '@mui/material/CircularProgress';
import CachedIcon from '@mui/icons-material/Cached';
import RenderDrugActionMessages from './RenderDrugActionMessages';
import {useTranslation} from 'react-i18next'
import RenderIcdCptActionMessages from './RenderIcdCptActionMessages';
import { useNavigate, useLocation } from 'react-router-dom';
import { validateUserResponse, validateAnEncounter } from '../../../Utils/validateHttpResponse';
import Toastify from '../../ui/Toastify';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Chip from '@mui/material/Chip';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { globalColors } from '../../../constants/ColorsPalette';

const EncounterCard = (encounter) => {
  const classes = EncounterCardStyle();
  const appCtx = useContext(AppContext);
  const authCtx = useContext(AuthContext);
  const userRole = appCtx.userRole;
  const token = authCtx.token;
  const [encounterObj, setEncounterObj] = useState(encounter.encounter);
  const [loadingEcounter, setLoadingEncounter] = useState(false);
  const [keepFetchingEncounter, setKeepFetchingEncounter] = useState(true);
  const { operation } = useAxios();
  const {t} = useTranslation('encounterCard')
  const direction = appCtx.direction
  const navigate = useNavigate();
  const location = useLocation();
  const disablePatientName = userRole === 'patient' || userRole === 'pharmacy_admin' || location.pathname.includes('/patient')

  //this use effect will only be triggered once.
  useEffect(() => {
    setEncounterObj(encounter.encounter)
    // eslint-disable-next-line
  }, [])

  const reFetchEncounter = () => {
    setLoadingEncounter(true);
    operation(
      {
        method: 'GET',
        url: `${urls.GET_ENCOUNTER}/${encounterObj.id}`,
        headers: { Authorization: `token ${token}` },
      },
      reloadedEncounterData,
      reloadedEncounterError
    );
  };

  const reloadedEncounterData = (newEncounterObj) => {
    setLoadingEncounter(false);
    if(validateAnEncounter(newEncounterObj)) {
      setEncounterObj(newEncounterObj);
    } else {
      console.log('invalid reloaded encounter response')
      Toastify({message: 'invalid reloaded encounter response', state: toastify_status.ERROR})
    }
    
  };

  const reloadedEncounterError = (error) => {
    setLoadingEncounter(false);
    console.error('Encounter fetch failed:', error);
  };

  const medicalCodeIcon = (medicalCode) => {
    return medicalCode.actionid.length > 0
      ? <CloseIcon sx={classes.close_icon}/>
      : <CheckIcon sx={classes.check_icon}/>
  }

  // this function called if user navigate to ptient profile page
  const getPatientFromPatientID = () => {
    operation(
      {
        method: 'GET',
        url: urls.GET_A_PATIENT + encounterObj.patient,
        headers: { Authorization: `token ${token}` },
        data: {},
      },
      navigateToProfile,
      (error) => {console.log(error)}
    );
  }

  const navigateToProfile = (user) => {
    if(validateUserResponse(user)) {
      appCtx.addSelectedUser(user);
      const path = app_paths.PATIENT_PROFILE.replace(':patientID', encounterObj.patient)
      navigate(path);
    } else {
      console.log('invalid user response')
      Toastify({message: 'invalid user response', state: toastify_status.ERROR})
    }
  }

  // Reload encounter every 5 seconds for 2 minutes, stop the reload if the 2 minutes passed or the encounter is in final state (MRE and PBM are done).
  useEffect(() => {
    if(authCtx.isLoggedIn && encounterObj.actionmsg === ENCOUNTER_ACTIONMSG.Pending_approval && keepFetchingEncounter) {
      setTimeout(() => {
        reFetchEncounter()
        appCtx.handleReFetchChartsData()
      }, reload_encounter.FREQUENCY)

      setTimeout(() => {
        setKeepFetchingEncounter(false)
      }, reload_encounter.DURATION)
    }
    // eslint-disable-next-line
  }, [encounterObj])

  return (
    <Accordion
      sx={ encounterObj?.actionid === -1
            ? classes.pending_approval_accordion
            : encounterObj?.actionid === 0
            ? classes.accept_accordion
            : encounterObj?.actionid === 1
            ? classes.partially_accept_accordion
            : classes.reject_accordion
      }
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} dir={direction} sx={classes.encounter_accordion_summary}>
          <Box sx={classes.encounter_accordion_summary_date_box}>
            <CalendarTodayIcon sx={classes.encounter_accordion_summary_date_icon} />
            <Typography sx={classes.encounter_accordion_summary_date_date}>{encounterObj?.date.split('T')[0]}</Typography>
          </Box>
        <Typography sx={[
          classes.encounter_accordion_summary_state_box,
          encounterObj.actionmsg === ENCOUNTER_ACTIONMSG.Approved
            ? classes.summary_approved_state_bg
            : encounterObj.actionmsg === ENCOUNTER_ACTIONMSG.Rejected
              ? classes.summary_rejected_state_bg
              : classes.summary_partially_approved_state_bg
        ]}>
            {t('State')}: {
              encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Approved
                ? t('Approved')
                : encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Partially_accepted
                  ? t("PartiallyAccepted")
                  : encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Rejected
                    ? t("Rejected")
                    : encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Pending_approval
                      ? t("PendingApproval")
                      : 'not valid state'
            }
          </Typography>

          <Box sx={classes.encounter_accordion_summary_patient_box}>
            <PersonIcon sx={classes.encounter_accordion_summary_patient_icon} />
            <Typography sx={classes.encounter_accordion_summary_patient_patient}>{t('Patient')}: {encounterObj.patient_name}</Typography>
          </Box>
      </AccordionSummary>

      {/* Diagnosis always exists in every encounter */}

      <AccordionDetails>
        <Box sx={[
          classes.encounter_accordion_header_info_box,
          encounterObj.actionmsg === "Approved"
            ? classes.encounter_accordion_header_info_box_approved
            : encounterObj.actionmsg === "Rejected"
              ? classes.encounter_accordion_header_info_box_rejected
              : classes.encounter_accordion_header_info_box_partially_approved
        ]
        }>
          {
            !disablePatientName && (
              <Typography sx={classes.info_text}>
                Patient:
                <span style={classes.pointer_info_text} onClick={getPatientFromPatientID}>
                  {encounterObj.patient_name}
                </span>
              </Typography>
            )
          }
          <Typography sx={classes.info_text} onClick={getPatientFromPatientID}>Provider: {encounterObj.provider_name}</Typography>
          <Typography sx={classes.info_text}>Reference Id: {encounterObj.reference_id} </Typography>
        </Box>

        <Box sx={classes.section_container}>
          <Box sx={classes.section_column}>
            <Typography sx={{
              ...classes.encounter_title,
              ...classes.medical_accordion_title
            }}>
                  <LocalHospitalIcon sx={classes.medical_icon} />
                  {t('Encounter Diagnosis')}
                </Typography>
            {encounterObj?.encountericds.map((icd) => (
              <Accordion
                key={icd.id}
                sx={[
                  classes.medical_accordion,
                  icd.actionid.length
                    ? classes.medical_accordion_rejected_border
                    : classes.medical_accordion_approved_border
                ]}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={[
                    classes.medical_accordion_summary,
                    icd.actionid.length
                      ? classes.medical_rejected_accordion_summary_bg
                      : classes.medical_approved_accordion_summary_bg
                  ]}
                >
                  <Box sx={classes.medical_summary_state_box}>
                    {medicalCodeIcon(icd)}
                    <Box sx={classes.medical_summary_info_box}>
                      <LocalHospitalIcon sx={[classes.medical_icon, {color: icd.actionid.length ? globalColors.red : globalColors.greenPea}]} />
                      <Box sx={classes.column_box}>
                        <Box sx={classes.align_icd_primary}>
                          <Typography sx={[classes.medical_code, icd.actionid.length 
                            ? classes.medical_rejected_code_color 
                            : classes.medical_approved_code_color]}>
                            ICD: ({icd.icd})
                          </Typography>
                          {icd.primary && (
                            <Chip
                              label={t('Primary')}
                              size="small"
                              sx={classes.medical_primary}
                            />
                          )}
                        </Box>
                        <Typography sx={[classes.mediacl_description, icd.actionid.length 
                          ? classes.mediacl_description_rejected_color 
                          : classes.mediacl_description_approved_color]}>
                          {icd.shortdescription}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={classes.medical_accordion_details}>
                  <Box sx={classes.medical_details_box}>
                    <Box sx={classes.medical_details_title_box}>
                      <Typography sx={classes.medical_info_title}>{t('Diagnosis Information')}</Typography>
                    </Box>
                    <Box sx={classes.medical_info_value_box}>
                      {[['ICD Code', icd.icd], ['Status', icd.actionid.length > 0 ? t('Rejected') : t('Approved'), icd.actionid.length > 0 ? globalColors.red : globalColors.greenPea], ['Primary Diagnosis', icd.primary ? t('Yes') : t('No')], ['Cost', icd.cost]].map(([label, value, color = globalColors.jetBlack]) => (
                        <Box key={label} sx={classes.column_box}>
                          <Typography sx={classes.medical_info_value_label}>
                            {t(label)}
                          </Typography>
                          <Typography sx={[classes.medical_info_value_values, {color}]}>
                            {value}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <RenderIcdCptActionMessages actionMessages={icd.actionmsg} />
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>

          {/* Check encounter engine and role and cpts to render Procedures*/}

          {(encounterObj?.engine === 'MRE' || encounterObj?.engine === 'ALL') && userRole !== 'pharmacy_admin' && encounterObj?.encountercpts.length > 0 && (
            <Box sx={classes.section_column}>
              <Typography sx={{
                    ...classes.encounter_title,
                    ...classes.medical_accordion_title
                  }}>
                    <MedicalInformationIcon sx={classes.medical_icon} />
                    {t('Encounter Procedures')}
                  </Typography>
              {encounterObj?.encountercpts.map((cpt) => (
                <Accordion
                  key={cpt.id}
                  sx={[
                    classes.medical_accordion,
                    cpt.actionid.length
                      ? classes.medical_accordion_rejected_border
                      : classes.medical_accordion_approved_border
                  ]}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={[
                      classes.medical_accordion_summary,
                      cpt.actionid.length
                        ? classes.medical_rejected_accordion_summary_bg
                        : classes.medical_approved_accordion_summary_bg
                    ]}
                  >
                    <Box sx={classes.medical_summary_state_box}>
                      {medicalCodeIcon(cpt)}
                      <Box sx={classes.medical_summary_info_box}>
                        <MedicalInformationIcon sx={[classes.medical_icon, {color: cpt.actionid.length ? globalColors.red : globalColors.greenPea}]} />
                        <Box sx={classes.column_box}>
                          <Typography sx={[classes.medical_code, cpt.actionid.length 
                            ? classes.medical_rejected_code_color 
                            : classes.medical_approved_code_color]}>
                            CPT: ({cpt.cpt})
                          </Typography>
                          <Typography sx={[classes.mediacl_description, cpt.actionid.length 
                          ? classes.mediacl_description_rejected_color 
                          : classes.mediacl_description_approved_color]}>
                            {cpt.shortdescription}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={classes.medical_accordion_details}>
                    <Box sx={classes.medical_details_box}>
                      <Box sx={classes.medical_details_title_box}>
                        <Typography sx={classes.medical_info_title}>
                          {t('Procedure Information')}
                        </Typography>
                      </Box>
                      <Box sx={classes.medical_info_value_box}>
                        {[['CPT Code', cpt.cpt], ['Status', cpt.actionid.length > 0 ? t('Rejected') : t('Approved'), cpt.actionid.length > 0 ? globalColors.red : globalColors.greenPea], ['Code Type', t('Procedure')], ['Cost', cpt.cost]].map(([label, value, color = globalColors.jetBlack]) => (
                          <Box key={label} sx={classes.column_box}>
                            <Typography sx={classes.medical_info_value_label}>
                              {t(label)}
                            </Typography>
                            <Typography sx={[classes.medical_info_value_values, {color}]}>
                              {value}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                      <RenderIcdCptActionMessages actionMessages={cpt.actionmsg} />
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
        </Box>

        {/* Check encounter engine and role and drugs to render Medications */}

        {(encounterObj?.engine === 'PBM' || encounterObj?.engine === 'ALL') && encounterObj?.encounterdrugs.length > 0 && (
          <>
            <Typography sx={{
                ...classes.encounter_title,
                ...classes.medical_accordion_title
              }}>
                <MedicalServicesIcon sx={classes.medical_icon} />
                {t('Encounter Medications')}
              </Typography>
            {encounterObj?.encounterdrugs.map((drug) => (
              <Accordion
                key={drug.id}
                sx={[
                  classes.medical_accordion,
                  drug.actionid.length
                    ? classes.medical_accordion_rejected_border
                    : classes.medical_accordion_approved_border
                ]}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={[
                    classes.medical_accordion_summary,
                    drug.actionid.length
                      ? classes.medical_rejected_accordion_summary_bg
                      : classes.medical_approved_accordion_summary_bg
                  ]}
                >
                  <Box sx={classes.medical_summary_state_box}>
                    {medicalCodeIcon(drug)}
                    <Box sx={classes.medical_summary_info_box}>
                      <MedicalServicesIcon sx={[classes.medical_icon, {color: drug.actionid.length ? globalColors.red : globalColors.greenPea}]} />
                      <Box sx={classes.column_box}>
                        <Typography sx={[classes.medical_code, drug.actionid.length 
                            ? classes.medical_rejected_code_color 
                            : classes.medical_approved_code_color]}>
                          {drug.drug.product_label_name}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={classes.accordionDetails}>
                  <Box>
                    {/* Medication Information Table */}
                    <Box sx={classes.infoCard}>
                      <Box sx={classes.cardHeader}>
                        <Typography sx={classes.cardHeaderText}>
                          {t('Medication Information')}
                        </Typography>
                      </Box>

                      <Box sx={classes.infoGrid}>
                        <Box sx={classes.infoItem}>
                          <Typography sx={classes.label}>
                            {t('Brand Name')}
                          </Typography>
                          <Typography sx={classes.value}>
                            {drug.drug.product_brand_name}
                          </Typography>
                        </Box>

                        <Box sx={classes.infoItem}>
                          <Typography sx={classes.label}>
                            {t('Generic Name')}
                          </Typography>
                          <Typography sx={classes.value}>
                            {drug.drug.generic_name}
                          </Typography>
                        </Box>

                        <Box sx={classes.infoItem}>
                          <Typography sx={classes.label}>
                            {t('Status')}
                          </Typography>
                          <Typography sx={{
                            ...classes.value,
                            color: drug.actionid.length > 0 ? globalColors.red : globalColors.greenPea,
                          }}>
                            {drug.actionid.length > 0 ? t('Rejected') : t('Approved')}
                          </Typography>
                        </Box>

                        <Box sx={classes.infoItem}>
                          <Typography sx={classes.label}>
                            {t('Cost')}
                          </Typography>
                          <Typography sx={classes.value}>
                            {drug.cost}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {/* Prescription Details Card */}
                    <Box sx={classes.infoCard}>
                      <Box sx={classes.cardHeader}>
                        <Typography sx={classes.cardHeaderText}>
                          {t('Prescription Details')}
                        </Typography>
                      </Box>

                      <Box sx={classes.infoGridPrescription}>
                        <Box sx={classes.infoItem}>
                          <Typography sx={classes.label}>
                            {t('Drug Dose')}
                          </Typography>
                          <Typography sx={classes.value}>
                            {drug.drug_dose}
                          </Typography>
                        </Box>

                        <Box sx={classes.infoItem}>
                          <Typography sx={classes.label}>
                            {t('Duration')}
                          </Typography>
                          <Typography sx={classes.value}>
                            {drug.duration}
                          </Typography>
                        </Box>

                        <Box sx={classes.infoItem}>
                          <Typography sx={classes.label}>
                            {t('Frequency')}
                          </Typography>
                          <Typography sx={classes.value}>
                            {drug.frequency}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {/* Quantity Information Card */}
                    <Box sx={classes.quantityCard}>
                      <Box sx={classes.cardHeader}>
                        <Typography sx={classes.cardHeaderText}>
                          {t('Quantity Information')}
                        </Typography>
                      </Box>

                      <Box sx={classes.infoGridPrescription}>
                        <Box sx={classes.infoItem}>
                          <Typography sx={classes.label}>
                            {t('Claimed Quantity')}
                          </Typography>
                          <Typography sx={classes.value}>
                            {drug.claimed_quantity}
                          </Typography>
                        </Box>

                        <Box sx={classes.infoItem}>
                          <Typography sx={classes.label}>
                            {t('Approved Quantity')}
                          </Typography>
                          <Typography sx={{
                            ...classes.value,
                            color: drug.approved_quantity > 0 && globalColors.greenPea,
                          }}>
                            {drug.approved_quantity}
                          </Typography>
                        </Box>

                        <Box sx={classes.infoItem}>
                          <Typography sx={classes.label}>
                            {t('Rejected Quantity')}
                          </Typography>
                          <Typography sx={{
                            ...classes.value,
                            color: drug.rejected_quantity > 0 && globalColors.red,
                          }}>
                            {drug.rejected_quantity}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {/* Action Messages */}
                    <RenderDrugActionMessages actionMessages={drug.actionmsg} />
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}

        {encounterObj?.notes && (
          <>
            <Typography sx={classes.encounter_title}>Encounter Notes:</Typography>
            <Typography sx={classes.encounter_title}>{encounterObj.notes}</Typography>
          </>
        )}

        <Box sx={[classes.reloadBox]}>
          <Typography sx={[classes.reloadBtn, encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Approved
            ? classes.btn_accepted_reload
            : encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Rejected
              ? classes.btn_rejected_reload
              : classes.btn_partially_accept_reload
          ]} onClick={reFetchEncounter}>
            <CachedIcon sx={classes.reloadIcon} />
            {t('Reload')}
          </Typography>
        </Box>

        {loadingEcounter && (
          <CircularProgress
            sx={[
              classes.circular,
              encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Approved
                ? classes.accepted_reload
                : encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Rejected
                ? classes.rejected_reload
                : classes.partially_accept_reload
            ]}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default EncounterCard;