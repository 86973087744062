import { motion } from 'framer-motion';
import TypingDotsStyle from './TypingDots.module';

const TypingDots = () => {
  const classes = TypingDotsStyle()
  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      {[0, 1, 2].map((i) => (
        <motion.div
          key={i}
          style={classes.dot}
          animate={classes.dot_animation}
          transition={{
            duration: 1.2,
            repeat: Infinity,
            ease: 'easeInOut',
            delay: i * 0.3,
          }}
        />
      ))}
    </div>
  );
};

export default TypingDots